import React, {useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import SanitizeHTML from "react-sanitized-html"

const Listing = (props) => {
  const projects = props.data.allProjects.edges;
  let orderedProjects = {};

  projects.forEach((project) => {
    if(typeof orderedProjects[project.node.publicationDate] === 'undefined')
      orderedProjects[project.node.publicationDate] = [];

    orderedProjects[project.node.publicationDate].push(project);
  })

  return (
    <main style={{backgroundColor: '#ECECEC'}}>
      <Header projectType={props.projectType} background={'#ECECEC'} {...props} />

      <section className="main-content">
        <div className="container-fluid pos-rel">
          <h1 className={"main listing-title"}>{props.projectType.title}</h1>

          {orderedProjects && Object.keys(orderedProjects).reverse().map((year) => {
            return orderedProjects[year].map((project, key) => {
              return (
                <div key={key} className="row listing">
                  <div className="col-3"><p>{key === 0 ? year : ''}</p></div>

                  <div className="col-9">
                    <p><a target={'_blank'} key={key} href={project.node.url}>{project.node.title}</a></p>
                  </div>
                </div>
              )
            })
          })}
        </div>
      </section>

      <Footer {...props} />
    </main>
  )
}

const Award = (props) => {
  return (
    <main style={{backgroundColor: '#ECECEC'}}>
      <Header projectType={props.projectType} background={'#ECECEC'} {...props} />

      <section className="main-content">
        <div className="container-fluid pos-rel">
          {props.data.allProjects && props.data.allProjects.edges.map((project, key) => {
            return (
              <div key={key} className="row award">
                <div className="col-lg-8">
                  <h1 className={"main"}><AniLink fade key={key} to={"/project/" + project.node.slug}>{project.node.title}</AniLink></h1>

                  <SanitizeHTML html={project.node.content}/>
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <Footer {...props} />
    </main>
  )
}

const Publication = (props) => {
  return (
    <main style={{backgroundColor: '#ECECEC'}}>
      <Header projectType={props.projectType} background={'#ECECEC'} {...props} />

      <section className="main-content">
        <div className="container-fluid pos-rel">
          <h1 className={"main"}>{props.projectType.title}</h1>

          {props.data.allProjects && props.data.allProjects.edges.map((project, key) => {
            // if (!project.node.images[0] || !project.node)
            //   return null

            return (
              <div key={key} className="row publication">
                <div className="col-md-4 col-sm-8 col-6 order-md-1 order-1 publication-details">
                  <p>
                    <strong>Book name:</strong><br />
                    {project.node.title}
                  </p>

                  <p>
                    <strong>Artist:</strong><br />
                    {project.node.titleArtists}
                  </p>

                  <p>
                    <strong>Publisher:</strong><br />
                    {project.node.publisher}
                  </p>

                  <p>
                    <strong>ISBN:</strong><br />
                    {project.node.ISBN}
                  </p>

                  <p>
                    <strong>Book format:</strong><br />
                    {project.node.bookFormat}
                  </p>

                  <p>
                    <strong>Publication date:</strong><br />
                    {project.node.publicationDate}
                  </p>

                  <p>
                    <strong>Price:</strong><br />
                    {project.node.price}
                  </p>
                </div>

                <div className="col-sm-8 col-md-4 col-12 order-md-2 order-3 publication-content">
                  <SanitizeHTML html={project.node.content}/>
                </div>

                <div className="col-md-4 col-sm-4 col-6 order-md-3 order-2">
                  <picture>
                    <img src={project.node.images[0].gallery} />
                  </picture>
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <Footer {...props} />
    </main>
  )
}

const ProjectType = (props) => {
    useEffect(() => {

    })

    const projectType = props.data.projectType.edges[0].node;

    if(!projectType)
      return null;

    // if(projectType.subType === 'Award'){
    //   return <Award projectType={projectType} {...props} />
    // }

    if(projectType.subType === 'Listing'){
      return <Listing projectType={projectType} {...props} />
    }

    if(projectType.subType === 'Publication'){
      return <Publication projectType={projectType} {...props} />
    }

    return (
      <main style={{backgroundColor: '#ECECEC'}}>
        <Header projectType={projectType} background={'#ECECEC'} {...props} />

        <section className="main-content">
          <div className="container-fluid pos-rel">
            <h1 className={"main"}>{projectType.title}</h1>

            {props.data.allProjects && props.data.allProjects.edges.map((project, key) => {
              if(!project.node.images[0] || !project.node)
                return null

              return (
                <div className="project">
                  <div className="row">
                    <div className="col-lg-9">
                      <AniLink fade key={key} to={"/project/" + project.node.slug}>
                        <picture>
                          <img src={project.node.images[0].gallery} />
                        </picture>
                      </AniLink>
                    </div>

                    <div className="col-lg-3">
                      <AniLink fade key={key} to={"/project/" + project.node.slug}>
                        <h3>{project.node.title}</h3>
                      </AniLink>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </section>

        <Footer {...props} />
      </main>
    )
}

export const query = graphql`
  query($slug: String) {
    projectType: allFullprojecttypes(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          subType
          title
          slug
        }
      }
    }    
    allProjects(filter: {projectType: {eq: $slug}}, sort: {fields: order, order: ASC}) {
      edges {
        node {
          projectType
          images {
            gallery
          }
          content
          slug
          title
          order
          publisher
          ISBN
          bookFormat
          publicationDate
          price
          titleArtists
          url
        }
      }
    }
  }
`

export default ProjectType